import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./Request.css";
import Header from "../../components/Header";

const Request = () => {
  const [tasks, setTasks] = useState([]); // To store filtered requests
  const [userRole, setUserRole] = useState(""); // Role of the user
  const [userPost, setUserPost] = useState(""); // Post of the user
  const isMounted = useRef(false); // Prevent multiple fetches on mount

  // Get user data from localStorage on component mount
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("userData"));
    if (data) {
      setUserRole(data.role);
      setUserPost(data.Post);
      console.log("User role:", data.role);
      console.log("User post:", data.Post);
    }
  }, []);

  // Fetch tasks filtered by user's post
  useEffect(() => {
    const fetchTasks = async () => {
      if (!userPost) return;

      try {
        const response = await axios.get("https://apikanvan.c2i.tn:5001/api/requestss", {
          params: { userPost }, // Send userPost as a query parameter
        });
        setTasks(response.data); // Set filtered tasks
        console.log("Fetched tasks:", response.data);
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    };

    fetchTasks();
    const interval = setInterval(fetchTasks, 1000); // Refresh tasks every second

    return () => clearInterval(interval); // Cleanup
  }, [userPost]); // Only run when userPost changes

  // Function to handle demande status change
  const handleDemandeChange = async (task) => {
    if (task.demande === "demander") {
      alert("This request is already set to 'demander' and cannot be changed.");
      return;
    }

    try {
      const response = await axios.put(`https://apikanvan.c2i.tn:5001/request/${task.id}/update-demande`, { demande: "demander" });
      // Update task in state
      setTasks((prevTasks) => prevTasks.map((t) => (t.id === task.id ? { ...t, demande: response.data.demande } : t)));
      console.log("Demande updated:", response.data);
    } catch (error) {
      console.error("Error updating demande:", error);
      alert("Failed to update demande.");
    }
  };

  return (
    <div className="fb">
      <Header title="Request" />
      <div className="task-table">
        <table>
          <thead>
            <tr>
              <th>Nom de la tâche</th>
              <th>Message de l'utilisateur</th>
              <th>Date</th>
              <th>Heure</th>
              <th>Statut</th>
              <th>Demande</th>
            </tr>
          </thead>
          <tbody>
            {tasks.map((task, index) => (
              <tr key={`${task.id}-${index}`}>
                <td>{task.Task}</td>
                <td>{task.userPost}</td>

                <td>{task.Date}</td>
                <td>{task.time}</td>
                <td>
                  <span className={`status-indicator ${task.status.toLowerCase().replace(" ", "-")}`}>{task.status}</span>
                </td>
                <td>
                  <span
                    className={`status-indicator2 ${task.demande.toLowerCase().replace(" ", "-")}${task.demande === "demander" ? " disabled" : ""}`}
                    onClick={() => {
                      if (task.demande !== "demander") handleDemandeChange(task); // Prevent clicks if "demander"
                    }}
                  >
                    {task.demande === "demander" ? "Demander" : "Click to Demander"}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Request;

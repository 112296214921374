import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Button, Typography, useTheme, FormControl } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";

const Kanvan = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [postOptions, setPostOptions] = useState([]);
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({ matricule: "", password: "", kanvanPost: "", date: "" });
  const [showAddForm, setShowAddForm] = useState(false);
  const [editUserData, setEditUserData] = useState(null);
  const [showEditForm, setShowEditForm] = useState(false);


  // Récupérer les Kanvans depuis l'API au montage du composant

  useEffect(() => {
    const fetchKanvans = async () => {
      try {
        const response = await axios.get("https://apikanvan.c2i.tn:5001/api/kanvan");
        setUsers(response.data);
      } catch (error) {

        console.error("Erreur lors de la récupération des kanvans:", error);

      }
    };
    const fetchPostOptions = async () => {
      try {
        const response = await axios.get("https://apikanvan.c2i.tn:5001/api/custom/getMag");
        const options = response.data.map((item) => ({

          label: item.name, // Mapper 'name' de l'API à 'label' pour le dropdown
          value: item.name, // Utiliser 'name' comme label et value
        }));
        setPostOptions(options);
      } catch (error) {
        console.error("Erreur lors de la récupération des postes:", error);

      }
    };
    fetchKanvans();
    fetchPostOptions();
  }, []);

  const handleAddUser = () => {
    setShowAddForm(true);
  };

  const handleCloseAddForm = () => {
    setShowAddForm(false);
  };

  const handleAddUserSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post("https://apikanvan.c2i.tn:5001/api/kanvan", newUser);
      setUsers([...users, response.data]);
      setNewUser({ matricule: "", password: "", kanvanPost: "", date: "" });
      setShowAddForm(false);
    } catch (error) {

      console.error("Erreur lors de l'ajout du kanvan:", error);

    }
  };

  const handleDeleteUser = async (id) => {

    const confirmDelete = window.confirm("Êtes-vous sûr de vouloir supprimer cet utilisateur ?");

    if (!confirmDelete) return;

    try {
      await axios.delete(`https://apikanvan.c2i.tn:5001/api/kanvan/${id}`);
      setUsers(users.filter((user) => user.id !== id));
    } catch (error) {

      console.error("Erreur lors de la suppression du kanvan:", error);

    }
  };

  const handleUpdateUser = (user) => {
    setEditUserData(user);
    setShowEditForm(true);
  };

  const handleCloseEditForm = () => {
    setShowEditForm(false);
  };

  const handleEditFormSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.put(`https://apikanvan.c2i.tn:5001/api/kanvan/${editUserData.id}`, editUserData);
      const updatedUsers = users.map((user) => (user.id === editUserData.id ? editUserData : user));
      setUsers(updatedUsers);
      setShowEditForm(false);
    } catch (error) {

      console.error("Erreur lors de la mise à jour du kanvan:", error);

    }
  };

  const columns = [
    { field: "matricule", headerName: "Matricule", flex: 1 },

    { field: "kanvanPost", headerName: "Magasin", flex: 1 },
    { field: "date", headerName: "Créé à", flex: 1 },
    {
      class: "edit",
      field: "edit",
      headerName: "Modifier",

      renderCell: (params) => (
        <Button onClick={() => handleUpdateUser(params.row)} sx={{ color: "white" }}>
          ✏️
        </Button>
      ),
    },
    {
      class: "delete",
      field: "delete",

      headerName: "Supprimer",
      renderCell: (params) => (
        <Button onClick={() => handleDeleteUser(params.row.id)} sx={{ backgroundColor: "rgb(241, 53, 53, 0.541)", color: "white" }}>
          🗑️

        </Button>
      ),
    },
  ];

  return (
    <Box m="20px" style={{ borderRadius: "80px", marginTop: "-5px" }} className="fb">
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <Header title="KANVAN" />
        <Button variant="contained" color="primary" onClick={handleAddUser} className="add-kanvan-btn">
          Ajouter
        </Button>
      </Box>

      <Box style={{ borderRadius: "80px", marginTop: "-25px" }} m="40px 0 0 0" height="75vh">
        <DataGrid
          rows={users}
          columns={columns}
          getRowId={(row) => row.id}
          components={{ Toolbar: GridToolbar }}
          className="customDataGrid"
          sx={{
            "& .MuiDataGrid-root": {
              color: "white",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              color: "white",
            },
            "& .MuiDataGrid-cell": {
              color: "white",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: colors.blueAccent[700],
            },
          }}
        />
      </Box>

      {showAddForm && (
        <div className="modal-overlay">
          <div className="edit-form">
            <h3 className="modal-title">Ajouter Kanvan</h3>
            <form onSubmit={handleAddUserSubmit}>
              <div className="form-row">
                <div className="form-column">
                  <label>
                    Matricule:
                    <input type="text" value={newUser.matricule} onChange={(e) => setNewUser({ ...newUser, matricule: e.target.value })} required />
                  </label>
                </div>
                <div className="form-column">
                  <label>
                    Magasin:
                    <select value={newUser.kanvanPost} onChange={(e) => setNewUser({ ...newUser, kanvanPost: e.target.value })} required>

                      <option value="">Choisir</option>

                      {postOptions.map((post, index) => (
                        <option key={index} value={post.value}>
                          {post.label}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
              </div>
              <div className="form-row">
                <div className="form-column">
                  <label>
                    Mot de passe:
                    <input type="password" value={newUser.password} onChange={(e) => setNewUser({ ...newUser, password: e.target.value })} required />
                  </label>
                </div>
              </div>
              <div className="form-row">
                <div className="form-column">
                  <label>

                    Crée à:

                    <input type="date" value={newUser.date} onChange={(e) => setNewUser({ ...newUser, date: e.target.value })} required />
                  </label>
                </div>
              </div>
              <div className="form-buttons">
                <button type="submit" className="save-btn">
                  Enregistrer
                </button>
                <button type="button" className="cancel-btn" onClick={handleCloseAddForm}>
                  Annuler
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showEditForm && editUserData && (
        <Box className="edit-form-overlay">
          <Box className="edit-form">
            <Typography className="titr" variant="h6">
              Modifier Kanvan
            </Typography>
            <form onSubmit={handleEditFormSubmit}>
              <div className="form-row">
                <div className="form-column">
                  <label>Matricule:</label>
                  <input type="text" value={editUserData.matricule} onChange={(e) => setEditUserData({ ...editUserData, matricule: e.target.value })} />
                </div>
                <div className="form-column">
                  <label>Mot de passe:</label>
                  <input type="text" value={editUserData.password} onChange={(e) => setEditUserData({ ...editUserData, password: e.target.value })} />
                </div>
              </div>
              <div className="form-row">
                <div className="form-column">
                  <label>
                    Magasin:
                    <select value={editUserData.kanvanPost} onChange={(e) => setEditUserData({ ...editUserData, kanvanPost: e.target.value })} required>

                      <option value="">Choisir</option>

                      {postOptions.map((post, index) => (
                        <option key={index} value={post.value}>
                          {post.label}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
              </div>
              <div className="form-row">
                <div className="form-column">

                  <label>Crée à:</label>

                  <input type="date" value={editUserData.date} onChange={(e) => setEditUserData({ ...editUserData, date: e.target.value })} />
                </div>
              </div>
              <div className="form-buttons">
                <Button type="submit" variant="contained" color="success">
                  Enregistrer
                </Button>
                <Button type="button" variant="contained" color="error" onClick={handleCloseEditForm}>
                  Annuler
                </Button>
              </div>
            </form>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Kanvan;
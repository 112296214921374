import React, { useState, useEffect } from 'react';
import './Estimate.css'; // Importer le CSS mis à jour

function Estimate() {
  const [pickupDetails, setPickupDetails] = useState([]);
  const [loading, setLoading] = useState(true); // État pour l'indicateur de chargement
  const [error, setError] = useState(null);    // État pour les messages d'erreur

  useEffect(() => {
    const fetchPickupDetails = async () => {
      try {
        const response = await fetch('https://apikanvan.c2i.tn:5001/api/pickup-time');
        if (!response.ok) {
          throw new Error(`Erreur HTTP ! Statut : ${response.status}`);
        }
        const data = await response.json();
        setPickupDetails(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPickupDetails();
  }, []);

  // Convert time into an appropriate unit
  const formatTime = (timeInSeconds) => {
    const time = parseFloat(timeInSeconds); // Safely parse the value
    if (isNaN(time)) return 'Non disponible';

    if (time < 60) {
      // If less than 60 seconds, display in seconds
      return `${Math.abs(time)} secondes`;
    } else if (time < 3600) {
      // If less than 3600 seconds (1 hour), display in minutes
      const minutes = Math.floor(time / 60);
      return `${minutes} minutes`;
    } else {
      // If 3600 seconds or more, display in hours
      const hours = (time / 3600).toFixed(2); // Keep 2 decimal places
      return `${hours} heures`;
    }
  };

  return (
    <div className="estimate-container">
      <header className="estimate-header">
        <h1 className="estimate-title">TABLEAU DE BORD DES ESTIMATIONS</h1>
      </header>
      {loading && <div className="loading">Chargement, veuillez patienter...</div>}
      {error && <p className="error-message">Erreur : {error}</p>}
      {!loading && !error && pickupDetails && pickupDetails.length > 0 ? (
        <div className="pickup-details">
          {pickupDetails.map((detail, index) => (
            <div className="pickup-card" key={index}>
              <div className="pickup-card-content">
                <h2 className="pickup-card-title">Magasin : {detail.kanvanPost}</h2>
                <p><strong>Matricule:</strong> {detail.kanvan_matricule}</p>
                <p><strong>Tâche:</strong> {detail.task}</p>
                <p>
                  <strong>Estimation de temps:</strong>{' '}
                  {formatTime(detail.time_difference)}
                </p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        !loading && <p className="no-data-message">Aucun détail de collecte disponible pour le moment.</p>
      )}
    </div>
  );
}

export default Estimate;

import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import axios from 'axios';
 
import './Pick_ups.css'; // Importation du CSS personnalisé pour un style supplémentaire
 

function Pick_ups() {
  const [data, setData] = useState([]);
  const [period, setPeriod] = useState('');
  const [courierId, setCourierId] = useState('');
  const [apiType, setApiType] = useState('pickups');
  const chartRef = useRef(null);
  let chartInstance = useRef(null);

 
  // Récupérer les données depuis l'API
 
  const fetchData = async () => {
    try {
      const apiUrl =
        apiType === 'pickups'
          ? 'https://apikanvan.c2i.tn:5001/api/pickups/stats'
          : 'https://apikanvan.c2i.tn:5001/api/demandes/stats';

      const params = {};
      if (period) params.period = period;
      if (courierId) params.courier_id = courierId;

      const response = await axios.get(apiUrl, { params });
      setData(response.data);

      const chartLabels = response.data.map((item) => item.courier_id);
      const chartValues = response.data.map((item) => item.pickup_count);

      updateChart(chartLabels, chartValues);
    } catch (error) {
 
      console.error('Erreur lors de la récupération des données :', error);
    }
  };

  // Créer ou mettre à jour l'instance de Chart.js
 
  const updateChart = (labels, values) => {
    if (chartInstance.current) {
      chartInstance.current.data.labels = labels;
      chartInstance.current.data.datasets[0].data = values;
      chartInstance.current.update();
    } else {
      const ctx = chartRef.current.getContext('2d');
      chartInstance.current = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [
            {
              label: apiType === 'pickups' ? 'Pickups' : 'Demandes',
              data: values,
              backgroundColor: 'rgba(54, 162, 235, 0.7)',
              borderColor: 'rgba(54, 162, 235, 1)',
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: true,
              position: 'top',
            },
          },
          scales: {
            x: {
              title: {
                display: true,
 
                text: 'ID du livreur',
 
                font: {
                  size: 14,
                },
              },
            },
            y: {
              title: {
                display: true,
 
                text: 'Nombre',
 
                font: {
                  size: 14,
                },
              },
              beginAtZero: true,
            },
          },
        },
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [period, courierId, apiType]);

  useEffect(() => {
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, []);

  return (
    <div className="pickups-container">
      <header className="header">
 
        <h1>{apiType === 'pickups' ? "STATISTIQUES DES PICKUPS" : "STATISTIQUES DES DEMANDES"}</h1>
      </header>

      {/* Section des filtres */}
      <div className="filters-container">
        <div className="filter-group">
          <label>Période :</label>
          <select value={period} onChange={(e) => setPeriod(e.target.value)}>
            <option value="">Tout</option>
            <option value="day">Aujourd'hui</option>
            <option value="week">Cette Semaine</option>
            <option value="month">Ce Mois</option>
 
          </select>
        </div>

        <div className="filter-group">
 
          <label>ID du livreur :</label>
 
          <input
            type="text"
            value={courierId}
            onChange={(e) => setCourierId(e.target.value)}
 
            placeholder="Entrez l'ID du livreur"
 
     
          />
        </div>

        <div className="filter-group">
 
          <label>Type d'API :</label>
 
          <select value={apiType} onChange={(e) => setApiType(e.target.value)}>
            <option value="pickups">Pickups</option>
            <option value="demandes">Demandes</option>
          </select>
        </div>
      </div>

 
      {/* Section du graphique */}
 
 
      <div className="chart-container">
        <canvas ref={chartRef}></canvas>
      </div>
    </div>
  );
}

export default Pick_ups;
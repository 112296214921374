import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Request.css";
import Header from "../../components/Header";

const Request = () => {
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]); // State for filtered tasks
  const [showForm, setShowForm] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editTaskId, setEditTaskId] = useState(null);
  const [error, setError] = useState(null); // API error state
  const [userRole, setUserRole] = useState("admin"); // Default role is admin
  const [postOptions, setPostOptions] = useState([]);
  const [taskFormData, setTaskFormData] = useState({
    Request: "",
    userPost: "",
    dueDate: "",
    status: "In Progress",
  });

  // Fetch user role from localStorage
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("userData"));
    if (data) {
      setUserRole(data.Post); // Assuming Post contains the role
      setTaskFormData((prev) => ({ ...prev, userPost: data.Post })); // Set userPost with userRole
      console.log("User Role:", data.Post);
    }
  }, []);

  // Fetch all tasks on component mount and filter by userRole
  useEffect(() => {
    const fetchData = async () => {
      try {
        setError(null); // Reset error
        const response = await axios.get("https://apikanvan.c2i.tn:5001/api/requests");
        const allTasks = response.data;

        // Filter tasks matching userRole
        const filtered = allTasks.filter((task) => task.userPost === userRole);
        setTasks(allTasks); // Store all tasks (optional, for debugging or other purposes)
        setFilteredTasks(filtered); // Store filtered tasks for display
      } catch (error) {
        console.error("Error fetching tasks:", error);
        setError("Failed to load tasks. Please try again.");
      }
    };
    const fetchPostOptions = async () => {
      try {
        const response = await axios.get("https://apikanvan.c2i.tn:5001/api/custom/getcustom");
        const options = response.data.map((item) => ({
          label: item.req, // Map 'name' from API to 'label' for dropdown
          value: item.req, // Use 'name' as both label and value
        }));
        setPostOptions(options);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };
    fetchPostOptions();
    fetchData();
    const interval = setInterval(fetchData, 1000); // Refresh tasks every second

    return () => clearInterval(interval); // Cleanup
  }, [userRole]); // Re-run if userRole changes

  const handleStatusClick = async (id) => {
    if (userRole === "admin" || userRole === "User") {
      console.log(userRole);
      return;
    }

    const updatedTasks = filteredTasks.map((task) => {
      if (task.id === id) {
        const newStatus = task.status === "In Progress" ? "Complete" : "In Progress";
        return { ...task, status: newStatus };
      }
      return task;
    });
    setFilteredTasks(updatedTasks);

    const updatedTask = updatedTasks.find((task) => task.id === id);
    try {
      await axios.put(`https://apikanvan.c2i.tn:5001/api/requests/${id}`, { ...updatedTask });
    } catch (error) {
      console.error("Error updating task status:", error);
      setError("Failed to update task status. Please try again.");
    }
  };

  const handleDelete = async (id, Task) => {
    const isConfirmed = window.confirm(`Are you sure you want to delete the task: "${Task}"?`);
    if (!isConfirmed) return;

    try {
      await axios.delete(`https://apikanvan.c2i.tn:5001/api/requests/${id}`);
      setFilteredTasks(filteredTasks.filter((task) => task.id !== id));
    } catch (error) {
      console.error("Error deleting task:", error);
      setError("Failed to delete task. Please try again.");
    }
  };

  const handleUpdate = (id) => {
    const taskToUpdate = filteredTasks.find((task) => task.id === id);
    setTaskFormData({
      Request: taskToUpdate.Task,
      userPost: userRole, // Always use current user's role
    
      status: taskToUpdate.status,
    });
    setEditTaskId(id);
    setIsEditing(true);
    setShowForm(true);
  };

  const handleAddOrUpdateTask = async (e) => {
    e.preventDefault();

    if (isEditing) {
      // Editing an existing task
      try {
        const updatedTask = {
          Task: taskFormData.Request,
          userPost: userRole, // Ensure userPost is tied to userRole
         
          status: taskFormData.status,
        };

        // API call to update the task
        await axios.put(`https://apikanvan.c2i.tn:5001/api/requests/${editTaskId}`, updatedTask);

        // Update task in the local state

        // Reset editing state
        setIsEditing(false);
        setEditTaskId(null);
      } catch (error) {
        console.error("Error updating task:", error);
        setError("Failed to update task. Please try again.");
      }
    } else {
      // Adding a new task
      try {
        const newTask = {
          Task: taskFormData.Request,
          userPost: userRole, // Ensure userPost is tied to userRole
         
          status: "In Progress",
        };

        // API call to add a new task
        const response = await axios.post("https://apikanvan.c2i.tn:5001/api/requests", newTask);

        // Add the new task to the local state
        setFilteredTasks((prevTasks) => [...prevTasks, response.data]);
      } catch (error) {
        console.error("Error adding task:", error);
        setError("Failed to add task. Please try again.");
      }
    }

    // Reset form and close modal
    setTaskFormData({ Request: "", userPost: userRole, dueDate: "", status: "In Progress" });
    setShowForm(false);
  };

  return (
    <div className="fb">
      <Header title="Request" />
      <div>
        {error && <div className="api-error">{error}</div>} {/* Error Message */}
        <button className="add-request-btn" onClick={() => setShowForm(true)}>
          Add Request
        </button>
        {showForm && (
          <div className="modal-overlay">
            <div className="modal-content">
              <h3>{isEditing ? "Edit Request" : "Add Request"}</h3>
              <form onSubmit={handleAddOrUpdateTask}>
                <label>
                  Task:
                  <select value={taskFormData.Request} onChange={(e) => setTaskFormData({ ...taskFormData, Request: e.target.value })} required>
                    <option value="">Choose</option>
                    {postOptions.map((post, index) => (
                      <option key={index} value={post.value}>
                        {post.label}
                      </option>
                    ))}
                  </select>
                </label>
                <label>
                  User Post:
                  <input value={userRole} disabled /> {/* User role displayed but not editable */}
                </label>
               
                
                <button type="submit" className="save-btn">
                  Enregistrer
                </button>
                <button type="button" className="cancel-btn" onClick={() => setShowForm(false)}>
                  Annuler
                </button>
              </form>
            </div>
          </div>
        )}
        <div className="task-table">
          <table>
            <thead>
              <tr>
                <th>Task Name</th>
                <th>User Post</th>
                <th>Due Date</th>
                <th>Heure</th>
                {userRole !== "admin" && <th>Action</th>}
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {filteredTasks.map((task) => (
                <tr key={task.id}>
                  <td>{task.Task}</td>
                  <td>{task.userPost}</td>
                  <td>{task.Date}</td>
                  <td>{task.time}</td>
                  {userRole !== "admin" && (
                    <td className="task-actions">
                      <button className="action-btn1 delete-btne" onClick={() => handleDelete(task.id, task.Task)}>
                        🗑️
                      </button>
                      <button className="action-btn1 update-btne" onClick={() => handleUpdate(task.id)}>
                        ✏️
                      </button>
                    </td>
                  )}
                  <td>
                    <span className={`status-indicator ${task.status.toLowerCase().replace(" ", "-")}`} onClick={() => userRole !== ("admin" || "User") && handleStatusClick(task.id)}>
                      {task.status}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Request;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "./UpdatePasswordForm.css";
import img from "./img/pass.png";

const UpdatePass = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userId, setUserId] = useState(null);

  // Visibility toggle states for each input
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      if (userData.id) setUserId(userData.id);
      if (userData.password) setCurrentPassword(userData.password);
    } else {
      console.error("No user data found in localStorage");
    }
  }, []);

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      alert("New password and confirm password do not match.");
      return;
    }

    try {
      const response = await axios.put(`https://apikanvan.c2i.tn:5001/api/users/${userId}/update`, { currentPassword, newPassword });

      if (response.data.success) {
        alert("Password updated successfully!");
        setCurrentPassword(newPassword);
        setNewPassword("");
        setConfirmPassword("");
        // Update user data in localStorage with the new password
        const updatedUserData = { ...JSON.parse(localStorage.getItem("userData")), password: newPassword };
        localStorage.setItem("userData", JSON.stringify(updatedUserData));
      } else {
        alert(response.data.message || "Failed to update password.");
      }
    } catch (error) {
      console.error("Error updating password:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <div className="update-password-container">
      <div className="profile-avatar1">
        <img src={img} alt="Profile Avatar" />
      </div>
      <h3>Changer mot de passe</h3>
      <form onSubmit={handleUpdatePassword}>
        {/* Current Password Field */}
        <label>
          mot de passe actuelle :
          <div className="password-input-container1">
            <input type={showCurrentPassword ? "text" : "password"} value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} aria-label="Current Password" required />
            <button type="button" className="toggle-password-btn1" onClick={() => setShowCurrentPassword(!showCurrentPassword)}>
              {showCurrentPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
        </label>

        {/* New Password Field */}
        <label>
          Nouveau mot de passe:
          <div className="password-input-container1">
            <input type={showNewPassword ? "text" : "password"} value={newPassword} onChange={(e) => setNewPassword(e.target.value)} aria-label="New Password" required />
            <button type="button" className="toggle-password-btn1" onClick={() => setShowNewPassword(!showNewPassword)}>
              {showNewPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
        </label>

        {/* Confirm New Password Field */}
        <label>
          Confirmer Nouveau mot de passe:
          <div className="password-input-container1">
            <input type={showConfirmPassword ? "text" : "password"} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} aria-label="Confirm New Password" required />
            <button type="button" className="toggle-password-btn1" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
        </label>

        <button type="submit" className="update-password-btn">
          mettre a jour
        </button>
      </form>
    </div>
  );
};

export default UpdatePass;

import React, { useState, useEffect } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar"; 
import User from "../Users/User";
import Kanvan from "../Kanvan/Kanvan";
import Profile from "../../Profile/Profile";
import UpdatePass from "../../UpdatePass/UpdatePass";
import logo from "../img/c2i.png";
import "./sidebar.css";
import "./navbar.css";

// Import icons
 
import { FiUser, FiLogOut, FiSettings, FiLock, FiUsers, FiTruck } from "react-icons/fi";
import { BsKanban } from "react-icons/bs";
import { FaRegListAlt, FaChartLine,FaMapMarkedAlt  } from "react-icons/fa"; // Updated for estimation
import Map from "../Map/Map";
import AddCust from "../AddCust/AddCust";
import Pick from "../Pick/Pick";
import Estimate from "../Estimate/Estimate";

const Navbar = ({ onNavClick }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [Name, setUsername] = useState("Profil");
 

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData && userData.Name) {
      setUsername(userData.Name);
    }
  }, []);

  const handleUserIconClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleLogoff = () => {
    localStorage.removeItem("userData");
    window.location.href = "/"; // Redirect to login page
  };

  return (
    <div className="navbar">
      <div className="navbar-item" onClick={handleUserIconClick}>
        <FiUser className="nn" />
        <span>{Name}</span>
      </div>
      {showDropdown && (
        <div className="dropdown-menu">
          <div className="dropdown-item" onClick={() => onNavClick("My Account")}>
            <FiSettings className="dropdown-icon" /> Mon compte
          </div>
          <div className="dropdown-item" onClick={() => onNavClick("Change Password")}>
            <FiLock className="dropdown-icon" /> Changer mot de passe
          </div>
          <div className="dropdown-item" onClick={handleLogoff}>
            <FiLogOut className="dropdown-icon" /> Se déconnecter
          </div>
        </div>
      )}
    </div>
  );
};

const SideBar = () => {
  const [selectedContent, setSelectedContent] = useState(<User />); // Set initial content to "Users"
  const [activeItem, setActiveItem] = useState("");

  const handleMenuItemClick = (item, content) => {
    setActiveItem(item);
    setSelectedContent(content);
  };

  const handleNavClick = (page) => {
    if (page === "My Account") {
      setSelectedContent(<Profile />);
    } else if (page === "Change Password") {
      setSelectedContent(<UpdatePass />);
    } else {
      setSelectedContent(`${page} Page Content`);
    }
  };

  return (
    <div style={{ display: "flex", height: "100vh", position: "relative" }}>
      {/* Navbar at the top */}
      <Navbar onNavClick={handleNavClick} />

      <Sidebar className={"sidebar"}>
        <div className="logo-container">
          <img src={logo} alt="Logo" className="sidebar-logo1" />
        </div>

        <Menu className="menu">
 
          <MenuItem onClick={() => handleMenuItemClick("Users", <User />)} className={`menu-item1 ${activeItem === "Users" ? "active" : ""}`}>
            <FiUsers className="menu-icon" /> <span className="menu-text">UTILISATEUR</span>
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("Kanban", <Kanvan />)} className={`menu-item1 ${activeItem === "Kanban" ? "active" : ""}`}>
            <BsKanban className="menu-icon" /> <span className="menu-text">KANVAN</span>
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("Add Customer", <AddCust />)} className={`menu-item1 ${activeItem === "Add Customer" ? "active" : ""}`}>
            <FaRegListAlt className="menu-icon" /> <span className="menu-text">AJOUTER CLIENT</span>
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("Estimate Stats", <Estimate />)} className={`menu-item1 ${activeItem === "Estimate Stats" ? "active" : ""}`}>
            <FaChartLine className="menu-icon" /> <span className="menu-text">ESTIMATIONS</span>
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("Pick-up Stats", <Pick />)} className={`menu-item1 ${activeItem === "Pick-up Stats" ? "active" : ""}`}>
            <FiTruck className="menu-icon" /> <span className="menu-text">PICK-UP STATS</span>
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("Map", <Map />)} className={`menu-item1 ${activeItem === "Map" ? "active" : ""}`}>
            <FaMapMarkedAlt  className="menu-icon" /> <span className="menu-text">MAP</span>
 
          </MenuItem>
        </Menu>
      </Sidebar>

      <div className="content1">{selectedContent}</div>
    </div>
  );
};

export default SideBar;

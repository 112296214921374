import React, { useState, useEffect } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import Hello from "./RequestU/Request";
import Hell2 from "../SideBarAdmin/RequestA/Request";
import Profile from "../Profile/Profile";
import UpdatePass from "../UpdatePass/UpdatePass";
import logo from "./img/c2i.png";

// Import icons
 
import { FiUser, FiLogOut, FiSettings, FiLock, FiFileText, FiClipboard } from "react-icons/fi";
 

const Navbar = ({ onNavClick }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [Name, setUsername] = useState("Profile");

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData && userData.Post) {
      setUsername(userData.Post);
    }
  }, []);

  const handleUserIconClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleLogoff = () => {
    localStorage.removeItem("userData");
    window.location.href = "/"; // Redirect to login page
  };

  return (
    <div className="navbar">
      <div className="navbar-item" onClick={handleUserIconClick}>
        <FiUser className="nn" />
        <span>{Name}</span>
      </div>
      {showDropdown && (
        <div className="dropdown-menu">
          <div className="dropdown-item" onClick={() => onNavClick("My Account")}>
            <FiSettings className="dropdown-icon" /> Mon compte
          </div>
          <div className="dropdown-item" onClick={() => onNavClick("Change Password")}>
            <FiLock className="dropdown-icon" /> Changer mot de passe
          </div>
          <div className="dropdown-item" onClick={handleLogoff}>
            <FiLogOut className="dropdown-icon" /> Déconnexion
          </div>
        </div>
      )}
    </div>
  );
};

const MenuUser = () => {
  const [selectedContent, setSelectedContent] = useState(<Hello />); // Set initial content to "Users"
  const [activeItem, setActiveItem] = useState("");

  const handleMenuItemClick = (item, content) => {
    setActiveItem(item);
    setSelectedContent(content);
  };

  const handleNavClick = (page) => {
    if (page === "My Account") {
      setSelectedContent(<Profile />);
    } else if (page === "Change Password") {
      setSelectedContent(<UpdatePass />);
    } else {
      setSelectedContent(`${page} Page Content`);
    }
  };

  return (
    <div style={{ display: "flex", height: "100vh", position: "relative" }}>
      {/* Navbar at the top */}
      <Navbar onNavClick={handleNavClick} />

      <Sidebar className={"sidebar"}>
        <div className="logo-container">
          <img src={logo} alt="Logo" className="sidebar-logo1" />
        </div>

        <Menu className="menu">
          <MenuItem onClick={() => handleMenuItemClick("Update Request", <Hello />)} className={`menu-item1 ${activeItem === "Update Request" ? "active" : ""}`}>
 
            <FiFileText className="menu-icon" /> <span className="menu-text">LISTE DE REQUEST</span>
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("ADD Request", <Hell2 />)} className={`menu-item1 ${activeItem === "ADD Request" ? "active" : ""}`}>
            <FiClipboard className="menu-icon" /> <span className="menu-text">DEMANDE</span>
 
          </MenuItem>
        </Menu>
      </Sidebar>

      <div className="content1">{selectedContent}</div>
    </div>
  );
};

export default MenuUser;
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import "./Users.css";

const Utilisateurs = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [optionsPoste, setOptionsPoste] = useState([]);
  const [utilisateurs, setUtilisateurs] = useState([]);
  const [nouvelUtilisateur, setNouvelUtilisateur] = useState({ Mat: "", password: "", Post: "", Date: "" });
  const [afficherFormulaireAjout, setAfficherFormulaireAjout] = useState(false);
  const [donneesUtilisateurModif, setDonneesUtilisateurModif] = useState(null);
  const [afficherFormulaireModif, setAfficherFormulaireModif] = useState(false);

  useEffect(() => {
    const recupererDonnees = async () => {
      try {
        const response = await axios.get("https://apikanvan.c2i.tn:5001/api/users");
        setUtilisateurs(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des utilisateurs :", error);
      }
    };
    const recupererOptionsPoste = async () => {
      try {
        const response = await axios.get("https://apikanvan.c2i.tn:5001/api/custom/getMag");
        const options = response.data.map((item) => ({
          label: item.name,
          value: item.name,
        }));
        setOptionsPoste(options);
      } catch (error) {
        console.error("Erreur lors de la récupération des postes :", error);
      }
    };

    recupererDonnees();
    recupererOptionsPoste();
    const intervalle = setInterval(recupererDonnees, 1000);

    return () => clearInterval(intervalle);
  }, []);

  const handleAjoutUtilisateur = () => {
    setAfficherFormulaireAjout(true);
  };

  const handleFermerFormulaireAjout = () => {
    setAfficherFormulaireAjout(false);
    setNouvelUtilisateur({ Mat: "", password: "", Post: "", Date: "" });
  };

  const handleSoumettreAjoutUtilisateur = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post("https://apikanvan.c2i.tn:5001/api/users", { ...nouvelUtilisateur, role: "User" });
      setUtilisateurs([...utilisateurs, response.data]);
      setNouvelUtilisateur({ Mat: "", password: "", Post: "", Date: "" });
      setAfficherFormulaireAjout(false);
    } catch (error) {
      console.error("Erreur lors de l'ajout de l'utilisateur :", error);
    }
  };

  const handleSupprimerUtilisateur = async (id) => {
    const confirmerSuppression = window.confirm("Êtes-vous sûr de vouloir supprimer cet utilisateur ?");
    if (!confirmerSuppression) return;

    try {
      await axios.delete(`https://apikanvan.c2i.tn:5001/api/users/${id}`);
      setUtilisateurs(utilisateurs.filter((utilisateur) => utilisateur.id !== id));
    } catch (error) {
      console.error("Erreur lors de la suppression de l'utilisateur :", error);
    }
  };

  const handleModifierUtilisateur = (utilisateur) => {
    setDonneesUtilisateurModif(utilisateur);
    setAfficherFormulaireModif(true);
  };

  const handleFermerFormulaireModif = () => {
    setAfficherFormulaireModif(false);
    setDonneesUtilisateurModif(null);
  };

  const handleSoumettreFormulaireModif = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.put(`https://apikanvan.c2i.tn:5001/api/users/${donneesUtilisateurModif.id}`, donneesUtilisateurModif);
      if (response.status === 200) {
        const utilisateursMisAJour = utilisateurs.map((utilisateur) => utilisateur.id === donneesUtilisateurModif.id ? { ...utilisateur, ...response.data.user } : utilisateur);
        setUtilisateurs(utilisateursMisAJour);
        setAfficherFormulaireModif(false);
      }
    } catch (error) {
      console.error("Erreur lors de la modification de l'utilisateur :", error);
    }
  };

  const colonnes = [
    { field: "Mat", headerName: "Matricule", flex: 1 },
    { field: "Post", headerName: "Magasin", flex: 1 },
    { field: "Date", headerName: "Créé le", flex: 1 },
    {
      field: "modifier",
      headerName: "Modifier",
      renderCell: (params) => (
        <Button onClick={() => handleModifierUtilisateur(params.row)} sx={{  color: "white" }}>
 
 ✏️
        </Button>
      ),
    },
    {
 
      field: "supprimer",
      headerName: "Supprimer",
      renderCell: (params) => (
        <Button onClick={() => handleSupprimerUtilisateur(params.row.id)} sx={{ backgroundColor: "rgb(241, 53, 53, 0.541)", color: "white" }}>
          🗑️
 
        </Button>
      ),
    },
  ];

  return (
    <Box m="20px" style={{ borderRadius: "80px", marginTop: "-5px" }} className="fb">
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
 
        <Header title="UTILISATEUR" />
        <Button variant="contained" color="primary" onClick={handleAjoutUtilisateur} className="add-kanvan-btn">
 
          Ajouter Utilisateur
        </Button>
      </Box>

      <Box style={{ borderRadius: "80px", marginTop: "-25px" }} m="40px 0 0 0" height="75vh">
        <DataGrid

          rows={utilisateurs}
          columns={colonnes}

          getRowId={(row) => row.id}
          components={{ Toolbar: GridToolbar }}
          className="customDataGrid"
          sx={{
            "& .MuiDataGrid-root": { color: "white" },
            "& .MuiDataGrid-columnHeaders": { backgroundColor: colors.blueAccent[700], color: "white" },
            "& .MuiDataGrid-cell": { color: "white" },
            "& .MuiDataGrid-virtualScroller": { backgroundColor: colors.primary[400] },
            "& .MuiDataGrid-footerContainer": { backgroundColor: colors.blueAccent[700] },
          }}
        />
      </Box>


      {afficherFormulaireAjout && (
        <div className="modal-overlay">
          <div className="edit-form">
            <h3 className="modal-title">Ajouter Utilisateur</h3>
            <form onSubmit={handleSoumettreAjoutUtilisateur}>
              <div className="form-row">
                <div className="form-column">
                  <label>
                    Matricule :
                    <input type="text" value={nouvelUtilisateur.Mat} onChange={(e) => setNouvelUtilisateur({ ...nouvelUtilisateur, Mat: e.target.value })} required />

                  </label>
                </div>
              </div>
              <div className="form-row">
                <div className="form-column">
                  <label>

                    Mot de passe :
                    <input type="password" value={nouvelUtilisateur.password} onChange={(e) => setNouvelUtilisateur({ ...nouvelUtilisateur, password: e.target.value })} required />

                  </label>
                </div>
                <div className="form-column">
                  <label>

                    Magasin :
                    <select value={nouvelUtilisateur.Post} onChange={(e) => setNouvelUtilisateur({ ...nouvelUtilisateur, Post: e.target.value })} required>
                      <option value="">Choisir</option>
                      {optionsPoste.map((poste, index) => (
                        <option key={index} value={poste.value}>
                          {poste.label}

                        </option>
                      ))}
                    </select>
                  </label>
                </div>
              </div>
              <div className="form-row">
                <div className="form-column">
                  <label>

                    Créé le :
                    <input type="date" value={nouvelUtilisateur.Date} onChange={(e) => setNouvelUtilisateur({ ...nouvelUtilisateur, Date: e.target.value })} required />

                  </label>
                </div>
              </div>
              <div className="form-buttons">
                <button type="submit" className="save-btn">
                  Enregistrer
                </button>

                <button type="button" className="cancel-btn" onClick={handleFermerFormulaireAjout}>

                  Annuler
                </button>
              </div>
            </form>
          </div>
        </div>
      )}


      {afficherFormulaireModif && donneesUtilisateurModif && (
        <Box className="edit-form-overlay">
          <Box className="edit-form">
            <Typography className="titr" variant="h6">
              Modifier Utilisateur
            </Typography>
            <form onSubmit={handleSoumettreFormulaireModif}>
              <div className="form-row">
                <div className="form-column">
                  <label>Matricule :</label>
                  <input type="text" value={donneesUtilisateurModif.Mat} onChange={(e) => setDonneesUtilisateurModif({ ...donneesUtilisateurModif, Mat: e.target.value })} />

                </div>
              </div>
              <div className="form-row">
                <div className="form-column">

                  <label>Mot de passe :</label>
                  <input type="password" value={donneesUtilisateurModif.password} onChange={(e) => setDonneesUtilisateurModif({ ...donneesUtilisateurModif, password: e.target.value })} />
                </div>
                <div className="form-column">
                  <label>
                    Magasin :
                    <select value={donneesUtilisateurModif.Post} onChange={(e) => setDonneesUtilisateurModif({ ...donneesUtilisateurModif, Post: e.target.value })} required>
                      <option value="">Choisir</option>
                      {optionsPoste.map((poste, index) => (
                        <option key={index} value={poste.value}>
                          {poste.label}

                        </option>
                      ))}
                    </select>
                  </label>
                </div>
              </div>
              <div className="form-row">
                <div className="form-column">

                  <label>Créé le :</label>
                  <input type="date" value={donneesUtilisateurModif.Date} onChange={(e) => setDonneesUtilisateurModif({ ...donneesUtilisateurModif, Date: e.target.value })} />

                </div>
              </div>
              <div className="form-buttons">
                <Button type="submit" variant="contained" color="success">

                  Enregistrer
                </Button>
                <Button type="button" variant="contained" color="error" onClick={handleFermerFormulaireModif}>
                  Annuler

                </Button>
              </div>
            </form>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Utilisateurs;
